import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProjectsPage = () => {
  const navigate = useNavigate();
  const [terminalOutput, setTerminalOutput] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const inputRef = useRef(null);
  const terminalEndRef = useRef(null);


  const experiences = [
    {
      title: "Mastercard",
      role: "Project Management Intern",
      time: "June - August 2024",
      place: "Kazakhstan",
      description: "Researched AI adoption in Kazakhstan banks, focusing on practical use cases. Conducted a survey among 100 bank employees and interviews with 4 people (NLP engineer, bank executives, and AI researchers). Analyzed findings to formulate a targeted roadmap, delivering impactful insights to Mastercard employees. Developed a web app presentation and a self assessment tool with tailored recommendations on AI adoption using React.js.",
    },
    {
      title: "Yandex",
      role: "Data Assessor",
      time: "May - July 2024",
      place: "Remote",
      description: "Analyzed 2000+ search queries in Kazakh, enhancing search engine accuracy. Evaluated the relevance and accuracy of search results, improving the Yandex neural network's performance by 10%",
    },
    {
        title: "Gensler",
        role: "Researcher, full stack developer",
        time: "October 2023 - July 2024",
        place: "San Francisco, CA",
        description: "Developed a web application that presents opportunities to small businesses, helping them access climate resources. Conducted user research to identify pain points and needs of small business owners. Designed and developed the application using React and MongoDB, enabling users to search for resources based on the risk types.",
    }, 
    {
        title: "Minerva University",
        role: "Student",
        time: "September 2023 - Present",
        place: "San Francisco, CA",
        description: "During freshman year, pitched projects in Silicon Valley hackathons, including at NASA, designing UI/UX for a scientist-project matching tool and an iOS health-tracking app. Helped manage 2 major tech events with Techstars in San Francisco and Silkroad Innovation Hub in Palo Alto.",
    },
    {
        title: "Codepath",
        role: "iOS101 Student, TIP102 Student",
        time: "February - August 2024",
        place: "Remote",
        description: "Successfully completed a 10-week iOS development course (grade 97.3%), learning Swift fundamentals and networking essentials, leading to the development of 8 iOS app projects. Accepted to a technical interview prep (intermediate level) course, learning data structures and algorithms to prepare for technical interviews.",
    }
  ];

  const projects = [
    {
      title: "Voiceletter.ai",
      time: "June 2024",
      link: "https://github.com/Nazym-MU/Voiceletter.ai",
      stack: "Node.js, Express, PostgreSQL, Swift, OpenAI API",
      github: "https://github.com/Nazym-MU/nFactorial-Ai-Cup-2024",
      youtube: "https://www.youtube.com/embed/2Zs_uZtXLyg", 
      description: "An iOS and web application that allows users to query news articles, automatically summarize them, and manage these summaries.",
    },
    {
      title: "Climate Action Toolkit",
      time: "October 2023 - July 2024",
      link: "https://climate-action-toolkit.vercel.app/",
      stack: "React.js, MongoDB",
      github: "https://github.com/Nazym-MU/climate-resources",
      youtube: "https://www.youtube.com/embed/pp7QMkmCkZY", 
      description: "A project that presents opportunities to small businesses, helping them access climate resources.",
    },
    {
      title: "Ayanat's Portfolio",
      time: "August 2024",
      link: "https://ayanat.vercel.app",
      stack: "React.js, CSS",
      github: "https://github.com/Nazym-MU/ayanat",
      youtube: "https://www.youtube.com/embed/JZsgg_xT384",
      description:
        "This portfolio website showcases my sister's work and skills in art. The repo is private. Planning to integrate authorization features, a database, CRUD for artworks, and an embedded contact form.",
    },
    {
      title: "Notes App",
      time: "April 2024",
      stack: "Node.js, Express.js, EJS, PostgreSQL",
      github: "https://github.com/Nazym-MU/NotesApp",
      youtube: "https://www.youtube.com/embed/OHAPZdPviX0", 
      description:
        "The Notes App is a web application designed for my efficient note management. It allows users to create, view, edit, and delete notes with a simple and intuitive interface. Users can organize their notes by title, content, author, rating, category, and date finished.",
    },
    {
      title: "Kettk",
      time: "April 2024",
      link: "https://www.figma.com/file/JLbrEfCeGTtgbVwcjvR37I/Storyboard?type=design&node-id=52%3A3&mode=design&t=WQIhEnVfaKGfyGkI-1",
      stack: "Swift",
      github: "https://github.com/Nazym-MU/Capstone",
      youtube: "https://www.youtube.com/embed/bsBkKVeTNKU", 
      description:
        "Kettk is an MVP of an event platform designed for Kazakhstan's two largest cities, Almaty and Astana. The app allows users to discover events, addressing the gap left by scattered event listings on social media and websites. It features user accounts, event feeds, search and filter functionalities, and the ability to follow accounts and favorite events. The app targets youth (16-25 years old) and aims to provide a centralized, user-friendly solution for event management.",
    }
  ];
  

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const scrollToBottom = () => {
    if (terminalEndRef.current) {
      terminalEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  
  const handleUserInput = (event) => {
    if (event.key === "Enter") {
      const input = event.target.value.trim();
      const output = `nazymzhiyengaliyeva@Nazyms-MacBook-Air ~ % ${input}`;
      const newOutput = [...terminalOutput, { type: 'command', content: output }];
  
      if (input === "cd experience") {
        const experienceOutput = experiences.map((exp) => ({
          type: 'output',
          content: `
  Title: ${exp.title || 'N/A'}
  Role: ${exp.role || 'N/A'}
  Time: ${exp.time || 'N/A'}
  Place: ${exp.place || 'N/A'}
  Description: ${exp.description || 'N/A'}
  --------------------`
        }));
        newOutput.push(...experienceOutput);
      } else if (input === "cd projects") {
        const projectsOutput = projects.map((proj) => ({
          type: 'output',
          content: `
  Title: ${proj.title || 'N/A'}
  Time: ${proj.time || 'N/A'}
  Stack: ${proj.stack || 'N/A'}
  Link: ${proj.link ? `<a href="${proj.link}" target="_blank" rel="noopener noreferrer">${proj.link}</a>` : 'N/A'}
  GitHub: ${proj.github ? `<a href="${proj.github}" target="_blank" rel="noopener noreferrer">${proj.github}</a>` : 'N/A'}
  YouTube: ${proj.youtube ? `<a href="#" onClick={() => handleYoutubeClick(proj.youtube)} style={styles.link}>View on YouTube</a>` : 'N/A'}
  Description: ${proj.description || 'N/A'}
  --------------------`,
          links: {
            link: proj.link,
            github: proj.github,
            youtube: proj.youtube
          }
        }));
        newOutput.push(...projectsOutput);
      } else if (input === "cd ..") {
        newOutput.push({ type: 'output', content: "Navigating to home page..." });
        navigate("/");
      } else if (input === "hint") {
        newOutput.push({ type: 'output', content: `Enter these commands:
  cd experience - View experiences
  cd projects - View projects
  cd .. - Go back to home page
  hint - Show this hint` });
      } else {
        newOutput.push({ type: 'output', content: `zsh: command not found: ${input.split(" ")[0]}` });
      }
  
      setTerminalOutput(newOutput);
      setUserInput("");
      scrollToBottom();
    } else {
      setUserInput(event.target.value);
    }
  };
  
  
  const handleYoutubeClick = (url) => {
    setYoutubeUrl(url);
    setShowYoutubeModal(true);
  };

  const handleYoutubeModalClose = () => {
    setShowYoutubeModal(false);
  };

  return (
    <div style={styles.container}>
  <div style={styles.terminal}>
    {terminalOutput.map((line, index) => (
      <div key={index} style={styles.outputLine}>
        {line.type === 'command' ? (
          <span>{line.content}</span>
        ) : (
          <pre style={styles.pre}>
            {line.content.split('\n').map((text, i) => (
              <span key={i}>
                {text.includes('Link:') ? (
                  <>
                    Link: <a href={line.links.link} target="_blank" rel="noopener noreferrer" style={styles.link}>{line.links.link}</a>
                  </>
                ) : text.includes('GitHub:') ? (
                  <>
                    GitHub: <a href={line.links.github} target="_blank" rel="noopener noreferrer" style={styles.link}>{line.links.github}</a>
                  </>
                ) : text.includes('YouTube:') ? (
                  <>
                    YouTube: <a href="#" onClick={() => handleYoutubeClick(line.links.youtube)} style={styles.link}>View on YouTube</a>
                  </>
                ) : (
                  text
                )}
                <br />
              </span>
            ))}
          </pre>
        )}
      </div>
    ))}
    <div ref={terminalEndRef} /> 
    <div style={styles.inputLine}>
      <span style={styles.prompt}>nazymzhiyengaliyeva@Nazyms-MacBook-Air ~ %</span>
      <input
        ref={inputRef}
        type="text"
        value={userInput}
        onChange={(event) => setUserInput(event.target.value)}
        onKeyDown={handleUserInput}
        style={styles.terminalInput}
        placeholder="Enter 'hint'"
      />
    </div>
  </div>
  {showYoutubeModal && (
    <div style={styles.youtubeModal}>
      <iframe
        width="640"
        height="360"
        src={youtubeUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <button onClick={handleYoutubeModalClose} style={styles.closeButton}>
        Close
      </button>
    </div>
  )}
</div>

  );
};

const styles = {
  container: {
    fontFamily: "monospace",
    backgroundColor: "#000000",
    color: "#FFFFFF",
    padding: "20px",
    height: "100vh",
    overflowY: "auto",
    width: "100%",
  },
  terminal: {
    fontSize: "16px",
  },
  outputLine: {
    marginBottom: "5px",
  },
  inputLine: {
    display: "flex",
    alignItems: "center",
  },
  prompt: {
    color: "#00FF00",
    marginRight: "8px",
  },
  terminalInput: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    border: "none",
    outline: "none",
    fontSize: "16px",
    fontFamily: "monospace",
    flexGrow: 1,
  },
  pre: {
    margin: 0,
    whiteSpace: "pre-wrap",
  },
  link: {
    color: "#00BFFF",
    textDecoration: "underline",
    cursor: "pointer",
  },
  youtubeModal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
  },
  closeButton: {
    marginTop: "20px",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
  },

  '@media (max-width: 768px)': {
    container: {
      padding: "10px",
      width: "90%"
    },
    terminal: {
      fontSize: "14px",
    },
    prompt: {
      marginRight: "5px",
    },
    terminalInput: {
      fontSize: "14px",
    },
    youtubeModal: {
      iframe: {
        width: "100%",
        height: "auto",
      },
    },
  },
};

export default ProjectsPage;