import React from "react";
import styled from "styled-components";


const IntroPopup = ({ onClose }) => {
  return (
    <Wrapper>
      <Background />
      <Content>
        <Title>Hi, I am Nazym!</Title>
        <Description>Welcome to my virtual workspace. This space is a window to my small world: each item you interact with reveals more about my journey, skills, and passions. Dive in and explore!</Description>
        <Button onClick={onClose}>Let's gooooo!</Button>
      </Content>
    </Wrapper>
  );
};

export default IntroPopup;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 10, 10, 0.7);
`;

const Content = styled.div`
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 20px;
    text-align: center;
    z-index: 2;
    max-width: 600px;
    width: 90%;
    position: relative;
    transform: rotate(-2deg);
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
    font-family: "Gloria Hallelujah", cursive;

    &::after {
    content: '';
    position: absolute;
    border: 1px solid #000;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    z-index: -1;
      }

      @media (min-width: 768px) {
        padding: 3rem;
      }
    
      @media (max-width: 480px) {
        padding: 1rem;
        border-radius: 15px;
        transform: none;
        max-width: 90%;
      }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: rgba(50, 10, 10, 0.7);
  font-family: "Gloria Hallelujah", cursive;

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.9;
  color: black;
  margin-bottom: 2rem;
  font-family: "Mali", cursive;
  font-weight: 300;

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const Button = styled.button`
  background-color: #fff;
  border: 2px solid;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.15rem;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  display: inline-block;
  z-index: 10;
  font-family: "Mali", cursive;
  font-weight: 400;
  color: #41403e;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  

  &:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

    &:focus {
        box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
    }

    @media (max-width: 480px) {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
      }
`;