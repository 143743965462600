import React, { useRef, useState, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF, OrbitControls } from "@react-three/drei";
import * as THREE from "three";
import HoverPoint from "./HoverPoint";
import { useNavigate } from "react-router-dom";

const Scene = () => {
  const { camera } = useThree();
  const model = useGLTF("/model-low.glb");
  const [focusedPoint, setFocusedPoint] = useState(null);
  const originalCameraPosition = useRef(camera.position.clone());
  const mixer = useRef(null);
  const navigate = useNavigate();

  const interestPoints = [
    { 
        position: [-9, -2, -2.6], 
        rotation: [0, 0, 0],
        name: "Resume", 
        type: "button",
        buttonText: "See Resume",
        onClick: () => window.open("/Nazym Zhiyengaliyeva Resume.pdf", "_blank")
      },
      { 
        position: [-8, 1.5, -2.6], 
        name: "Birthplace", 
        type: "info",
        content: "It all started in Kaskelen, Kazakhstan. This small town near Almaty is where I was born 19 years ago. From Kaskelen, I moved to Oskemen, Kapshagai (Konayev), Almaty, and Astana, and each of them left its mark on me and shaped who I am today."
      },
      { 
        position: [-2, 2, -2.6], 
        name: "Education", 
        type: "info",
        content: "In 7th grade, I got into NIS Almaty with the highest score on the entrance exam, and this was the beginning of a transformative journey. This prestigious school provided resources and environment to push my boundaries. My 6 years there were filled with ups and downs, but I ultimately graduated in 2023 with honors, getting A* in A-levels in Physics and Math, and an A in Computer Science."
      },
      {
        position: [-1, -5, 2],
        name: "Blog",
        type: "button",
        buttonText: "Read Blog",
        onClick: () => window.open("https://nazym.substack.com/", "_blank")
      },
      {
        position: [-12, -1, -2.6],
        name: "Uni",
        type: "info",
        content: "Minerva University in San Francisco was a new chapter in my life. I'll be studying in 7 countries, surrounded by a community of creative, extraordinary, and supportive people. There, I found a family and a home far away from home. It has shifted my career goals, deeply impacted my personal growth, expanded my comfort zone and worldview. Even though Minerva wasn’t part of my original plan, it’s turned out to be the best place I could think of to spend my undergrad years!",
      },
      {
        position: [-13, -3, -2.6],
        name: "KZ Choco",
        type: "info",
        content: "My collection of Kazakhstan chocolate wrappers is a sweet reflection of my adventures around the world. Each wrapper represents not just a treat but a memory of the people I’ve enjoyed it with, sharing stories and experiences over tea."
      },
      {
        position: [-2.5, -5, -2.6],
        name: "Internship",
        type: "info",
        content: "I love exploring the unknown and learning quickly from unfamiliar experiences. Mastercard in Kazakhstan has been one of those experiences and I am enjoying it a lot. I was selected as one of the 10 interns out of 300+ applicants, and now I am researching AI adoption in Kazakhstan banks. Have learned a lot so far and excited for more exciting opportunities in the future!"
      },
      {
        position: [0.5, -3, -2.6],
        name: "Lyrics",
        type: "info",
        content: "MJ Prologue is a song from a Kazakh artist Jeltoksan. The lyrics resonate with me a lot, and I turn to its lyrics for inspiration whenever I feel lost."
      },
      {
        position: [8, 2, -2.6],
        name: "Languages",
        type: "info",
        content: "I speak Kazakh, Russian, English, and know German and Ukrainian. Learned German at school, got a DSDII C1 certificate in 2023. Actually was planning to study in Germany before getting into Minerva."
      },
      {
        position: [11, 1.3, -2.6],
        name: "Architecture",
        type: "info",
        content: "I love architecture and had a dream of becoming an architect when I was a kid. This is a recommendation letter from an internship at ArchCode in Almaty in 2022. I think it was one of the key reasons why I got into Minerva."
      },
      {
        position: [12.2, -2, -2.6],
        name: "Manchester United",
        type: "info",
        content: "My love for Manchester United is a source of immense joy and inspiration. I laughed a lot and I cried a lot because of this club, it's hard being a fan. But it's more than just a club for me - it's a driving force in my life. My dream is to visit Old Trafford before it's demolished. Meeting them in Astana in 2019 was one of the best moments of my life."
      },
      {
        position: [5, -3, -2.6],
        name: "Travel",
        type: "info",
        content: "These are the countries and cities I visited. I love traveling and want to keep traveling as much as I can."
      },
      {
        position: [0, -5, 0],
        name: "Projects",
        type: "button",
        buttonText: "See Projects",
        onClick: () => navigate('/projects')
      },
      {
        position: [1.3, -5.3, 2],
        name: "Contact",
        type: "contact",
      }
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (model.animations.length > 0) {
      mixer.current = new THREE.AnimationMixer(model.scene);

      model.animations.forEach((clip) => {
        if (clip.name === 'manchesterAction') {
          const action = mixer.current.clipAction(clip);
          action.setLoop(THREE.LoopRepeat, Infinity); 
          action.play();
        }
      });
    }
  }, [model]);

  useFrame((state, delta) => {
    if (mixer.current) mixer.current.update(delta);

    if (focusedPoint) {
      camera.position.lerp(
        new THREE.Vector3(...focusedPoint.position).add(new THREE.Vector3(5, 0, 5)),
        0.05
      );
      camera.lookAt(...focusedPoint.position);
    } else if (!isMobile) {
      camera.position.lerp(originalCameraPosition.current, 0.05);
    }
  });

  return (
    <group>
      <hemisphereLight intensity={1.85} groundColor='black' />
      <primitive
        object={model.scene}
        scale={3}
        position={[0, -10, 0]}
        rotation={[0, 0, 0]} 
      />
      {interestPoints.map((point, index) => (
        <HoverPoint
          key={index}
          {...point}
          setFocusedPoint={setFocusedPoint}
        />
      ))}
      <OrbitControls
        enableZoom={true}
        enableRotate={true}
        enablePan={true}
        maxPolarAngle={Math.PI / 2} 
        minDistance={5}
        maxDistance={50}
        target={[0, 0, 0]}
        />
    </group>
  );
};

export default Scene;