import React, { Suspense, useRef, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload } from "@react-three/drei";
import Scene from "./Scene";
import IntroPopup from "./IntroPopup";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProjectsPage from "./ProjectsPage";

/*
Further plans (September 15):
- Update Blender model with new projects
- Update the laptop interface to include GUI and CLI options
- Add two buttons on load for quick portfolio landing page and more comprehensive portfolio
- Add a button to switch between the two portfolio options
- Update the contact page with a form to send an email to the admin
*/

const App = () => {
  const orbitControlsRef = useRef();
  const [showIntro, setShowIntro] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleCameraChange = () => {
      if (orbitControlsRef.current) {
        const distance = orbitControlsRef.current.target.distanceTo(orbitControlsRef.current.object.position);

        if (!isMobile) {
          if (distance < 20) {
            orbitControlsRef.current.maxPolarAngle = Math.PI;
            orbitControlsRef.current.minPolarAngle = 0;
          } else {
            orbitControlsRef.current.maxPolarAngle = Math.PI / 2;
            orbitControlsRef.current.minPolarAngle = 0;
          }
        } else {
          orbitControlsRef.current.maxPolarAngle = Math.PI;
          orbitControlsRef.current.minPolarAngle = 0;
        }
      }
    };

    if (orbitControlsRef.current) {
      orbitControlsRef.current.addEventListener('change', handleCameraChange);
    }

    return () => {
      if (orbitControlsRef.current) {
        orbitControlsRef.current.removeEventListener('change', handleCameraChange);
      }
    };
  }, [isMobile]);

  const handleButtonClick = () => {
    setShowIntro(false);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Canvas
              frameloop='demand'
              shadows
              camera={{ position: [0, -20, 30], fov: 35 }}
              gl={{ preserveDrawingBuffer: true }}
            >
              <Suspense>
                <OrbitControls
                  ref={orbitControlsRef}
                  enableZoom={true}
                  maxPolarAngle={isMobile ? Math.PI : Math.PI / 2}  // Allow free vertical movement on mobile
                  minPolarAngle={0}
                  minAzimuthAngle={-Infinity}
                  maxAzimuthAngle={Infinity}
                  target={[0, 0, 0]}
                />
                <Scene />
              </Suspense>
              <Preload all />
            </Canvas>
            {showIntro && <IntroPopup onClose={handleButtonClick} />}
          </>
        } />
        <Route path="/projects" element={<ProjectsPage />} />
      </Routes>
    </Router>
  );
};

export default App;