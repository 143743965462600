import React, { useState, useRef } from "react";
import { Html } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Popup = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  color: white;
  max-width: 500px;
  min-width: 200px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  overflow: auto;
  z-index: 1000;

  @media (max-width: 768px) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    height: auto;
    font-size: 14px;
    overflow-y: auto;
  }
`;

const CloseButton = styled.button`
  padding: 5px 10px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const InfoContent = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  color: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  min-width: 300px;

  @media (max-width: 768px) {
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
  }
`;

const ContactIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin: 0 auto;

  @media (max-width: 768px) {
    justify-content: center;
    width: 90%;
    height: 90%;
  }
`;

const IconStyle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: white;
  border-radius: 50%;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
`;

const Button = styled.button`
  display: block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
    position: relative;
    width: auto;
  }
`;

// HoverPoint Component
const HoverPoint = ({ position, type, buttonText, content, onClick, setFocusedPoint }) => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.scale.x = meshRef.current.scale.y = meshRef.current.scale.z =
        hovered ? 1.3 : 1;
    }
  });

  const handleClick = () => {
    setClicked(true);
    setFocusedPoint({ position, type, buttonText, content, onClick });
  };

  const handleGoBack = () => {
    setClicked(false);
    setFocusedPoint(null);
  };

  return (
    <group position={position}>
      <mesh
        ref={meshRef}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
        onClick={handleClick}
      >
        <sphereGeometry args={[0.2, 20, 20]} />
        <meshBasicMaterial color="white" transparent opacity={0.5} />
      </mesh>
      {clicked && (
        <Html>
          <Popup>
            <CloseButton onClick={handleGoBack}>X</CloseButton>
            {type === 'button' && <Button onClick={onClick}>{buttonText}</Button>}
            {type === 'info' && <InfoContent>{content}</InfoContent>}
            {type === 'contact' && (
              <ContactIcons>
                <IconStyle href="https://t.me/zhiyengaliyeva" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTelegramPlane} />
                </IconStyle>
                <IconStyle href="tel:+16284449597">
                  <FontAwesomeIcon icon={faPhone} />
                </IconStyle>
                <IconStyle href="mailto:nazym@uni.minerva.edu">
                  <FontAwesomeIcon icon={faEnvelope} />
                </IconStyle>
                <IconStyle href="https://www.linkedin.com/in/zhiyengaliyeva/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} />
                </IconStyle>
              </ContactIcons>
            )}
          </Popup>
        </Html>
      )}
    </group>
  );
};

export default HoverPoint;
